/* -----defult css start----- */

body {
  font-family: "Inter", "sans-serif" !important;
}

* {
  padding: 0px;
  margin: 0px;
}

:root {
  --btn: #1c74bb;
  --font1: #ffffff;
  --font2: #002e5b;
  --font3: #1c74bb;
  --font4: #aad1ec;
}

body {
  font-family: inherit;
}

/* -----defult css end----- */

/* -----Heder css start----- */

.logo img {
  width: 220px;
  height: 65px;
}

.buutton a {
  background-color: var(--btn);
  width: 230px;
  height: 55px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: var(--font1);
  font-size: 20px;
  font-weight: 600;
}

.navbar-nav a {
  font-size: 20px;
  font-weight: 600;
  padding: 20px 15px 20px 15px !important;
  color: var(--font2);
  text-decoration: none;
}

.navbar-nav a:hover {
  color: var(--font3);
}

@media only screen and (max-width: 992px) {
  .buutton {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .navbar-nav a {
    font-size: 18px;
    padding: 15px 10px 15px 10px !important;
  }
}

/* -----Heder css and----- */

/* -----Footer start----- */

.box-footer h3 {
  font-size: 36px;
  color: var(--color);
  font-weight: 600;
  margin-bottom: 30px;
}

.box-footer p {
  font-size: 18px;
  color: #696969;
}

.box-footer .input input {
  width: 320px;
  height: 45px;
  border: 0;
  outline: 0;
  background-color: #f2e9e9;
  border-radius: 50px 0 0 50px;
  padding-left: 20px;
  font-size: 19px;
}

.box-footer .input button {
  padding: 9px 22px;
  margin-left: -20px;
  background-color: var(--font3);
  color: white;
  font-size: 15px;
  border: 0;
  border-radius: 50px;
}

.input {
  padding-bottom: 30px;
}

.box-fo-link ul {
  list-style: none;
  padding: 0 !important;
}

.box-fo-link {
  gap: 100px;
}

.box-fo-link a {
  text-decoration: none;
  color: #696969;
  font-size: 16px;
}

.box-fo-link ul li {
  padding: 10px 0;
}

.box-fo-link p {
  margin-bottom: 20px !important;
}

.bottom h3 {
  font-size: 15px;
  color: black;
}

.box-fo-link h5 {
  font-size: 18px;
  color: #696969;
}

.footer-text h2 {
  font-size: 18px;
  color: #002e5b;
  font-weight: 600;
}

@media only screen and (max-width: 576px) {
  .box-footer h3 {
    font-size: 30px;
  }

  .box-footer p {
    font-size: 15px;
  }

  .box-footer .input input {
    width: 200px;
    height: 40px;
  }

  .box-footer .input button {
    padding: 8px 10px;
  }
  .footer_address::placeholder {
    font-size: 14px;
  }
}

/* -----Footer end----- */

/* -----Sec_H_1 start----- */

.slider_top {
  padding: 25px;
}

.slider_top p {
  font-size: 25px;
  color: var(--font3);
  padding-top: 45px;
}

.slider_top h3 {
  color: var(--font2);
  font-size: 30px;
  padding-top: 20px;
}

.Sec_H_1 {
  background-color: var(--font4);
  padding-top: 50px;
  padding-bottom: 50px;
}
.s_img img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px;
}
.box_S_1 p {
  font-size: 14px;
}
.slider_bottom {
  position: relative;
}
.slider_bottom img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 25px;
}
.slider_contain {
  width: 65%;
  background-color: #dadbdc;
  border-radius: 10px;
  padding: 10px;
}

.box_S_1 {
  background-color: white;
  border-radius: 10px;
  padding: 5px;
}
.slider_contain_2 {
  width: 65%;
  background-color: #dadbdc;
  border-radius: 50px;
  padding: 8px;
}

.box_S_2 {
  background-color: white;
  border-radius: 50px;
  padding: 12px;
}
.box_S_2 {
  font-size: 14px;
}

.box_S_2 h5 {
  font-size: 20px;
}
.slider_contain_3 {
  width: 60%;
  background-color: #dadbdc;
  border-radius: 50px;
  padding: 10px;
}
.box_S_3 {
  background-color: white;
  border-radius: 50px;
  padding: 10px;
}
.box_S_3 p {
  font-size: 14px;
}
.star-icon li svg {
  font-size: 18px;
  margin: 1px;
  color: #ffc700;
}
.rec_box h1 {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #d9d9d9;
}
@media only screen and (min-width: 576px) {
  .slider_contain {
    position: absolute;
    top: 30px;
    left: -140px;
    animation: upDown 2s infinite;
  }
  @keyframes upDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .slider_contain_2 {
    position: absolute;
    top: 280px;
    left: -110px;
    animation-name: upDown1;
    animation-direction: alternate;
    transition: all 0.3s;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
  @keyframes upDown1 {
    0% {
      top: 70%;
    }
    100% {
      top: 75%;
    }
  }
  .slider_contain_3 {
    position: absolute;
    top: 150px;
    right: -120px;
    animation: upDown 2s infinite;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .slider_bottom img {
    width: 80%;
    height: 400px;
    object-fit: cover;
    border-radius: 25px;
  }
  .slider_bottom {
    display: flex;
    justify-content: center;
  }
  .slider_contain {
    width: 50%;
    position: absolute;
    top: 30px;
    left: -28px;
  }
  .slider_contain_2 {
    width: 45%;
    position: absolute;
    top: 280px;
    left: -30px;
  }
  .slider_contain_3 {
    width: 45%;
    position: absolute;
    top: 150px;
    right: -28px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .slider_bottom img {
    width: 80%;
    height: 400px;
    object-fit: cover;
    border-radius: 25px;
  }
  .slider_bottom {
    display: flex;
    justify-content: center;
  }
  .slider_contain {
    width: 50%;
    position: absolute;
    top: 30px;
    left: -28px;
  }
  .slider_contain_2 {
    width: 45%;
    position: absolute;
    top: 280px;
    left: -30px;
  }
  .slider_contain_3 {
    width: 45%;
    position: absolute;
    top: 150px;
    right: -28px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .slider_bottom img {
    width: 80%;
    height: 350px;
    object-fit: cover;
    border-radius: 25px;
  }
  .slider_bottom {
    display: flex;
    justify-content: center;
  }
  .slider_contain {
    width: 60%;
    position: absolute;
    top: 30px;
    left: -28px;
  }
  .slider_contain_2 {
    width: 50%;
    position: absolute;
    top: 240px;
    left: -30px;
  }
  .slider_contain_3 {
    width: 50%;
    position: absolute;
    top: 150px;
    right: -28px;
  }
}
@media (min-width: 1216px) and (max-width: 1288px) {
  .slider_contain_3 {
    /* width: 45%; */
    position: absolute;
    top: 150px;
    right: -80px;
  }
}
@media (max-width: 576px) {
  .slider_contain {
    width: 100% !important;
    margin-top: 30px;
  }
  .slider_contain_2 {
    width: 100%;
    margin-top: 15px;
  }
  .slider_contain_3 {
    margin-top: 15px;

    width: 100%;
  }
}
@media (max-width: 576px) {
  .slider_top {
    padding: 25px;
  }

  .slider_top p {
    font-size: 20px;
  }

  .slider_top h3 {
    color: var(--font2);
    font-size: 25px;
    padding-top: 20px;
  }
}

/* -----Sec_H_1 end----- */

/* -----Sec_H_2 start----- */

.Sec_H_2 {
  width: 100%;

  padding: 100px 0px;
}

.icon-1 a {
  font-size: 50px;
  margin: auto;
  width: 100px;
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid var(--font2);
  background-color: var(--font4);
  color: var(--font2);
}

.icon-1 {
  padding-bottom: 20px;
  /* margin:  auto; */
}

.Box-1 {
  padding: 20px;
  text-align: center !important;
  padding: 20px 30px 20px 30px;
  width: 100%;
  /* display: flex;
  justify-content: center; */
}

.Box-1 h4 {
  padding-bottom: 12px;
  color: var(--font2);
}

.Box-1 p {
  color: var(--font3);
}

/* -----Sec_H_2 end----- */

/* -----Sec_H_3 start----- */
.Sec_H_3 {
  background-color: var(--font4);
}

.About img {
  width: 100%;
  height: 470px;
  object-fit: cover;
  border-radius: 15%;
}

.About {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.About_contain {
  padding-top: 20px;
  padding-bottom: 20px;
}

.About_contain p {
  font-size: 22px;
  color: var(--font3);
  padding-bottom: 20px;
}

.About_contain h3 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 30px;
  color: var(--font2);
}

.About_contain ul li {
  font-size: 15px;
  list-style-type: none;
  padding-bottom: 22px;
  color: #1c74bb;
}

.About_contain h2 {
  background-color: var(--font3);
  color: white;
  font-size: 20px;
  width: 170px;
  height: 45px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 30px;
  cursor: pointer;
}

.main_Box {
  padding-top: 60px;
  padding-bottom: 60px;
}

.hh {
  align-items: center;
  display: flex;
}

.product img {
  width: 30px;
  height: 30px;
}

.product h3 {
  font-size: 25px;
}
@media (max-width: 576px) {
  .About img {
    width: 100%;
    height: 400px !important;
  }
}

/* -----Sec_h_3 end----- */

/* -----Sec_H_4 start----- */

.BoxH_4 {
  text-align: center;
}

.BoxH_4 p {
  font-size: 20px;
  font-weight: 800;
  color: var(--font2);
}

.BoxH_4 h4 {
  font-size: 15px;
  color: var(--font3);
}

.pp {
  padding: 90px 0px 90px 0px;
}

@media only screen and (max-width: 768px) {
  .BoxH_4 {
    padding: 35px;
  }
}

.cart .mm {
  height: 400px;
}

.mm img {
  width: 100%;
  height: 100%;
}

.Main_Cart {
  background-color: #aad1ec;
  padding: 60px 0px;
}

.nav {
  list-style-type: none;
  transition: 3s !important;
}

.tt {
  color: var(--font2);
}
.nav li:hover {
  color: var(--font3);
}
@media (max-width: 576px) {
  .mm {
    padding-top: 15px;
  }
}

/* -----Sec_H_4 end ----- */

/* -----Sec_H_5 start----- */

.image img {
  width: 100%;
  height: 400px;
  /* object-fit: cover; */
}

.image {
  margin: 30px 40px;
}
.hed_h {
  color: var(--font2);
}
.text-ui {
  background-color: white;
}

.gg {
  padding: 40px 0px;
  background-color: var(--font4);
}

.main_Sec_H_5 {
  padding: 60px 10px;
}

@media only screen and (max-width: 576px) {
  .image {
    margin: 0px;
  }

  .icon {
    width: 80px !important;
    height: 80px !important;
  }
}

.icon {
  width: 70px;
  height: 70px;
}

/* -----Sec_H_5 end----- */

/* =========================================About componenst================================ */

/* -----Sec_A_1 start----- */

.hed {
  text-align: center;
  padding: 120px 0px;
}

.hed h1 {
  font-family: Arial, sans-serif;
  /* Use a font that looks similar */
  font-size: 70px;
  /* Adjust size to your needs */
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 2px var(--font2);
  /* Black outline */
  text-transform: uppercase;
}

.c-1 h4 {
  color: var(--font2);
}

.c-1 h5 {
  color: var(--font3);
}

.c-1 p {
  color: var(--font3);
}

.A_Contain {
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 100px 0px;
}

.c-2 {
  list-style-type: none;
}

.sec_A_1 {
  background-color: var(--font4);
}

.A_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-top: 20px;
}

.A_btn a {
  background-color: var(--font2);
  color: white;
  padding: 15px 30px;
  border-radius: 10px;
  text-decoration: none;
}

@media only screen and (max-width: 576px) {
  .hed h1 {
    font-size: 35px;
  }
}

/* -----Sec_A_2 end----- */

/* ======================================Contat component======================================= */

/* -----Sec_c_1 start----- */

.hed-2 {
  padding: 120px 0px;
  background-color: var(--font4);
}

.hed-2 h1 {
  font-family: Arial, sans-serif;
  /* Use a font that looks similar */
  font-size: 70px;
  /* Adjust size to your needs */
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 2px var(--font2);
  /* Black outline */
  text-transform: uppercase;
}

@media only screen and (max-width: 576px) {
  .hed-2 h1 {
    font-size: 35px;
  }
}

.Box-1 a {
  color: var(--font3);
}

/* -----Sec_C_1 end----- */

/* ========================Career_Componest start===================== */

/* ------Sec_C2_1 start------ */

.Sec_C2_1 input {
  width: 100%;
  border: none;
  padding: 10px 15px;
  background-color: #c9e0f7;

  border-radius: 10px;
}
::placeholder {
  color: #030508;
}
.C2_Contain {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 11%, rgba(201,224,247,1) 46%, rgba(255,255,255,1) 75%);
  padding: 30px 0px;
}
.C2_Contain h2 {
  color: var(--font2);
  font-weight: 600;
}
.C2_info {
  color: var(--font3);
}
.C2_Contain p {
  font-size: 16px;
  /* color: var(--font4); */
}
@media (max-width: 576px) {
  .hed-2 h1 {
    font-size: 35px;
  }
}
@media (max-width: 1200px) {
  .C2_Contain h2 {
    font-size: 25px;
  }
}
@media (max-width: 576px) {
  .C2_Contain h2 {
    font-size: 20px;
  }
}
.Sec_C2_1 {
  background-color: var(--font3);
  padding: 60px 0px;
}
.C2_main_class {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
}

.drag-drop-container {
  border: 2px dashed var(--font2);
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #666;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.drag-drop-container:hover {
  background-color: #f0f0f0;
}

.browse-label {
  display: block;
  color: #666;
}

.browse-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.browse-link:hover {
  color: #0056b3;
}

/* ------Sec_C2_1 end------ */
